var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Dialog',{attrs:{"create":true,"title":"快捷回复","left":140,"top":105,"width":1748},on:{"on-close":_vm.closeIDsPopup}},[_c('div',{staticClass:"Box"},[_c('div',{staticClass:"select"},[_c('div',{staticClass:"btn",on:{"click":_vm.exportFn}},[_vm._v("新增")])]),_c('Table',{staticClass:"task-table",attrs:{"columns":_vm.columns,"dataSource":_vm.dataSource,"pagination":_vm.pagination},on:{"size-change":(size) => {
                            _vm.pagination.pageSize = size;
                            _vm.async_get_list();
                        },"page-change":(page) => {

        _vm.async_get_list(page);
    }},scopedSlots:_vm._u([{key:"action",fn:function({ row }){return [_c('div',{staticClass:"action"})]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }