<template>
    <div class="deviceBox">
        <Menu @on-Click="showList" :iconList="list" />
        <div>
            <component @closeDemand="closeDemand" :is="type[showListType]"> </component>
        </div>
    </div>
</template>
<script >
import Menu from "@/components/newmenu/menu";
import Table1 from "./components/table1";//公告管理
import Table2 from "./components/table2"
// 意见反馈
import Table3 from "./components/table3";
// 快捷回复
import Table4 from "./components/table4";
//人员信息
import Table5 from "./components/table5";

export default {
    components: { Menu, Table1, Table2, Table3,Table4,Table5 },
    data() {
        return {
            list:
                [
                    {
                        id: 1,
                        name: '公告管理',
                    },
                    // {
                    //     id: 2,
                    //     name: '行业管理',
                    // },
                    {
                        id: 3,
                        name: '意见反馈',
                    },
                    {
                        id: 4,
                        name: '人员信息',
                    },
               
                ],
            showListType: 1, // 控制展示左侧哪个导航
            type: {
                1: Table1,
                // 2: Table2,
                3: Table3,
                // 4: Table4,
                4: Table5
            }
        }
    },
    created() {
        if (!window.viewer.scene.globe.show) {
            window.plate_data_source.show = false
            window.viewer.scene.globe.show = true
        }
    },
    methods: {
        closeDemand() {
            this.showListType = false
        },
        showList(item) {
            this.showListType = item.id
            this.$forceUpdate()
        },
    },
}

</script>
<style scoped  lang="scss">
.deviceBox {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 108px;
    height: 100%;
}
</style>