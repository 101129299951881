<template>
    <div>
        <Dialog :create="true" @on-close="closeIDsPopup" title="快捷回复" :left="140" :top="105" :width="1748">
            <!--待审批 -->
            <div class="Box">
                <!-- 赛选栏 -->
                <div class="select">
                    <div class="btn" @click="exportFn">新增</div>
                </div>
                <!-- 表格 -->
                <Table class="task-table" :columns="columns" :dataSource="dataSource" :pagination="pagination"
                    @size-change="
                        (size) => {
                            pagination.pageSize = size;
                            async_get_list();
                        }
                    " @page-change="
    (page) => {

        async_get_list(page);
    }
">
                    <!-- <template #taskCateName="{ row }">
              <div class="action" :style="getStatusColor.getTaskColor(row.taskCateName === '常态任务' ? 2:3 )">
                {{ row.taskCateName}}
              </div>
            </template> -->

                    <template #action="{ row }">
                        <div class="action">

                        </div>
                    </template>
                </Table>
            </div>
        </Dialog>
    </div>
</template>
<script>
import Dialog from "@/pages/civilAircraft/components/dialog"
import Table from "@/components/common/table-new-hwb"; // 列表组件

export default {
    components: { Dialog, Table },

    data() {
        return {
            dataSource: [],
            columns: [
                { prop: "index", label: "序号", width: "100px" },
                { prop: "taskTitle", label: "创建时间", width: "230px" },
                { prop: "taskCateName", slot: true, label: "添加人", width: "170px" },
                { prop: "taskTypeName", label: "标签", width: "170px" },
                { prop: "papplyStatus", slot: true, label: "内容", width: "400px" },
                { prop: "organizationName", label: "开放等级", width: "170px" },
                { prop: "organizationName1", label: "使用次数", width: "170px" },
                { prop: "action", slot: true, label: "操作", width: "170px" },
            ],
            pagination: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
            edit_form: {  // 发起需求表单字段
                approvalStatus: null, // 审批状态赛选
                organId: null, // 机构赛选的id
                taskCate: null, // 任务类型
                taskType: null, // 场景类型
                name: null,
            },
            selectList: null, // 赛选的机构
            drd: {
                cates: [
                    { id: 2, title: "常态任务" },
                    { id: 3, title: "临时紧急任务" },
                ],
                scenes: null,
                uav_type: [],
                mount_type: [],
                task_type: [],
                task_cate: [],
                flightLine: [],
                proStatus: [
                    { id: 1, label: "待处理", value: 0 },
                    { id: 2, label: "处理完成", value: 1 },
                    { id: 3, label: "无需处理", value: 2 },
                ],
                cityType: [
          {
            id: 13,
            title: "济源市",
            children: [
              { id: 1, title: "沁园街道" },
              { id: 2, title: "济水街道" },
              { id: 3, title: "北海街道" },
              { id: 4, title: "天坛街道" },
              { id: 5, title: "玉泉街道" },
              { id: 6, title: "克井镇" },
              { id: 7, title: "五龙口镇" },
              { id: 8, title: "轵城镇" },
              { id: 9, title: "承留镇" },
              { id: 10, title: "邵原镇" },
              { id: 11, title: "坡头镇" },
              { id: 12, title: "梨林镇" },
              { id: 17, title: "大峪镇" },
              { id: 14, title: "思礼镇" },
              { id: 15, title: "王屋镇" },
              { id: 16, title: "下冶镇" },


            ],
          },
        ],
            },
        }
    },
    methods: {
        reset() {

        },
        exportFn() {

        },
        getColor(color) {
            return `color: ${color}`
        },
        approvalStatusClick(num) {
            if (this.edit_form.approvalStatus == num) {
                this.edit_form.approvalStatus = null
            } else {
                this.edit_form.approvalStatus = num
            }

        },
        searchData() { // 搜索按钮
            this.pagination.page = 1
            this.async_get_list()
        },
        closeIDsPopup() {
            this.$emit('closeDemand');
        },
        async_get_list() {

        }
    },
}
</script>
<style lang="scss" scoped>
.Box {
    min-height: 60vh;

    .select {
        margin: 32px 0 24px 0;

        ::v-deep {
            .el-input__inner {
                background-color: #000;
                border: 0;
                height: 30px;
                line-height: 30px;
                color: #fff;
                font-size: 14px;
            }
        }

        .button {
            margin-right: 10px;
            width: 210px;
        }

        .search-button {
            display: inline-block;
            width: 54px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;
            border-radius: 4px;
            background-color: #000;
            color: #fff;
            text-align: center;
            box-sizing: border-box;
        }
        .btn {
            padding: 5px 10px;
            display: inline-block;
            background: rgba(38, 71, 238, 0.71);
            box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
            border-radius: 2px;
            border: 1px solid;
            // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            margin-right: 10px;
            cursor: pointer;
        }

        .btn:hover {
            background: rgba(38, 71, 238, 0.91);

        }
    }

    .task-table {
        min-height: 60vh;
    }
}
</style>