<template>
    <div>
        <Dialog :create="true" @on-close="closeIDsPopup" title="人员信息" :left="140" :top="105" :width="1748">
            <!--待审批 -->
            <div class="Box">
                <!-- 赛选栏 -->
                <div class="head">
                    <div class="select">
                    <JsonExcel class="dib" :fields="json_fields" :name="'人员信息统计.xls'" :fetch='fetch'>
                    <div class="btn">导出</div>
                    </JsonExcel>
                    <!-- <div style="margin-left:20px" class="btn" @click="exportFn">全部导出</div> -->
                </div>
                <div class="select right">
                    <el-input v-model="name" class="w210 mr10 dib " size="mini" placeholder="请输入姓名"
                        clearable>
                    </el-input>
                    <div class="btn" @click="searchFn">搜索</div>
                </div>
                </div>
                  <el-table
                  class="hwb-table-new"
                        ref="multipleTable"
                        :data="dataSource"
                        tooltip-effect="dark"
                        style="width: 100%"
                        @selection-change="handleSelectionChange"
                        :header-row-style="{ 'background-color': 'rgba(138,152,177,0.28)', color: '#fff', 'font-size': '14px' }"
                        :header-row-class-name="'header-row-class-name'" :max-height="550" :row-class-name="tableRowClassName"
                        :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
                        :cell-style="{'textAlign':'center'}" :highlight-current-row="false"
                        :header-cell-style="{ textAlign: 'center' }"
                        height="50vh"
                        :row-key="getRowKeys"
                        >
                        <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="55">
                        </el-table-column>
                        <el-table-column
                        type="index"
                        label="序号"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="createdAt"
                        label="创建时间"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="userName"
                        label="姓名"
                        width="120">
                        </el-table-column>
                        <el-table-column
                        prop="userPhone"
                        label="手机号"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="userNumber"
                        label="身份证"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="photo"
                        label="个人照片"
                        width="180">
                         <template slot-scope="{row}">
                        <div class="photo">
                            <img @click="imgClick(row.photo,1)" :src="row.photo" alt="" class="w44 h36 img-click">
                        </div>
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop="positive"
                        label="身份证正面照"
                        width="180">
                         <template slot-scope="{row}">
                        <div class="photo">
                            <img @click="imgClick(row.positive,2)" :src="row.positive" alt="" class="w44 h36 img-click">
                        </div>
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop="reverse"
                        label="身份证反面照"
                        width="180">
                        <template slot-scope="{row}">
                        <div class="photo">
                            <img @click="imgClick(row.reverse,3)" :src="row.reverse" alt="" class="w44 h36 img-click">
                        </div>
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop="userState"
                        label="操作"
                        width="200"
                        show-overflow-tooltip>
                        <template slot-scope="{row}">
                            <div class="action">
                            <div @click="userStateChange(row,0)" class="btn" :class="row.userState==0? 'selected' : ''">禁用</div>
                            <div @click="userStateChange(row,1)" class="btn" :class="row.userState==1? 'selected' : ''">启用</div>
                            </div>
                        </template>
                        </el-table-column>
                    </el-table>
                    <div class="table-pagination">
                        <Pagination
                        @size-change="handle_size_change"
                        @page-change="handle_page_change"
                        :current-page="pagination.page || 1"
                        :page-sizes="pagination.pageSizes || [10, 20, 50, 100]"
                        :page-size="pagination.pageSize || 10"
                        :total="pagination.total || 0"
                        />
                    </div>
            </div>
        </Dialog>
        <DialogBig v-if="bigImg" @on-close="closePhoto" :title="phopoTitle" :width='800' >
          <div class="wih100 h500" style="overflow-y: auto;">
            <img class="w760 " :src="bigImg" alt="">
          </div>
        </DialogBig>
    </div>
</template>
<script>
import Dialog from "@/pages/civilAircraft/components/dialog"
import DialogBig from "@/components/common/new-dialog/index"
import Table from "@/components/common/table-new-hwb"; // 列表组件
import API from '@/api'
import Pagination from "@/components/common/pagination";
import JsonExcel from 'vue-json-excel'
export default {
    components: { Dialog, Table , DialogBig , Pagination , JsonExcel },
    data() {
        return {
            exportName:null,
            checkedArr:[],
            bigImg:null,//大图
            phopoTitle:'',//大图标题
            name:null,//搜索
            dataSource: [],
            pagination: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
            json_fields: { //导出
                "序号": "index",    //常规字段
                "创建时间": "createdAt",
                "姓名": "userName",
                "手机号": "userPhone",
                "身份证": "userNumber",
                "个人照片": "photo",
                "身份证正面照": "positive",
                "身份证反面照": "reverse",
                "是否启用": "userState",
            },
        }
    },
    methods: {
        fetch(){
            let checkList = JSON.parse(JSON.stringify(this.checkedArr))
            checkList.forEach((v,index)=>{//启用禁用
               v.userState = v.userState==0 ? '禁用' : '启用'
               v.userNumber = "'" + v.userNumber
               v.index= index+1
            })
           let dataStr = checkList.map(v=>v.userName).join(',')
           this.exportName = checkList.length >2  ? `${checkList[0]}等${checkList.length}个人员信息` : `${dataStr}-人员信息`
           return checkList
        },
        getRowKeys(row){
            return row.id
        },
        handle_size_change(value){
            this.pagination.pageSize = value
            this.async_get_list()
        },
        handle_page_change(value){
            this.pagination.page = value
            // checkedAll.push(checkedArr)
            this.async_get_list()
        },
        tableRowClassName({ row, rowIndex }) {  // 控制任务状态
        if (rowIndex % 2 == 0) {
        return 'dan-row';
        } else if (rowIndex % 2 == 1) {
            return 'shuang-row';
        }
      },
        handleSelectionChange(value){
            this.checkedArr = value
        },
        imgClick(img,state){
            switch (state) {
                case 1: this.phopoTitle = '个人照片'
                    break;
                case 2: this.phopoTitle = '身份证正面照'
                    break;
                case 3: this.phopoTitle = '身份证反面照'
                    break;
                default:
                    break;
            }
            this.bigImg = img
        },
        closePhoto(){
            this.bigImg = null 
        },
       async userStateChange(row,state){
            if (row.userState != state) { //当状态不相同才请求
                let res = await API.AIRCRAFT.UserFreeze({id:row.id,state})
            this.async_get_list()
            if (res.code==200) {
                this.$message.success(state==0?'禁用成功':'启用成功')   
            } else {
                this.$message.error(res.errorMessage)   
            }
            }
        },
        reset() {

        },
        searchFn() { //搜索
            this.async_get_list()
        },
        exportFn() { //导出
            
        },
        getColor(color) {
            return `color: ${color}`
        },
        approvalStatusClick(num) {
            if (this.edit_form.approvalStatus == num) {
                this.edit_form.approvalStatus = null
            } else {
                this.edit_form.approvalStatus = num
            }

        },
        searchData() { // 搜索按钮
            this.pagination.page = 1
            this.async_get_list()
        },
        closeIDsPopup() {
            this.$emit('closeDemand');
        },
       async async_get_list() {
        let res = await API.AIRCRAFT.UserInfo({
            name:this.name,
            page:this.pagination.page,
            size:this.pagination.pageSize
        })
        console.log(res);
        if (res.code==200) {
            this.dataSource=res.data
            this.pagination.total = res.total
        }
        }
    },
    created(){
        this.async_get_list()
    }
}
</script>
<style lang="scss" scoped>
.Box {
    min-height: 60vh;
          .table-pagination {
        flex-shrink: 0;
        height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
      }
    .head{
        display: flex;
        justify-content: space-between;
    }
    .select {
        display: flex;
        margin: 0px 0 24px 0;
        &.right{
            display: flex;
        }
        ::v-deep {
            .el-input__inner {
                background-color: #000;
                border: 0;
                height: 30px;
                line-height: 30px;
                color: #fff;
                font-size: 14px;
            }
        }

        .button {
            margin-right: 10px;
            width: 210px;
        }

        .search-button {
            display: inline-block;
            width: 54px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;
            border-radius: 4px;
            background-color: #000;
            color: #fff;
            text-align: center;
            box-sizing: border-box;
        }
        .btn {
            width: 86px;
            height: 30px;
            background: rgba(38,71,238,0.71);
            box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0,18,55,0.5);
            border-radius: 2px;
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
            cursor: pointer;
            text-align: center;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 30px;
        }

        .btn:hover {
            background: rgba(38, 71, 238, 0.91);

        }
    }
    .action{
        display: flex;
        justify-content: space-around;
        .btn {
            width: 58px;
            height: 30px;
            background: #02173D;
            box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0,18,55,0.5);
            border-radius: 2px;
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
            cursor: pointer;
            text-align: center;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 30px;
            &.selected{
                background: rgba(38,71,238,0.71);
                cursor: not-allowed;
            }
        }

    }
    .photo{
        .img-click{
            cursor: pointer;
        }
    }
    .task-table {
        min-height: 60vh;
    }
}
</style>