<template>
  <el-dialog
    :title="title"
    class="hwb-dialog"
    :top="top + 'px'"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :width="width + 'px'">
    <div class="guanbi" @click="close">{{closeTitle}}</div>
    <img class="jiantou" src="./img/jt.png"/>
    <slot/>
  </el-dialog>
</template>

<script>
export default {
  props: {
    closeTitle: {
      type: String,
      default: '关闭'
    },
    width:{  // 控制宽度
      type:Number,
      default: 400
    },
    title: { // 标题
      type: String,
      default: '请传入一个标题放在这里'
    },
    top: {
      type:Number,
      default: 200
    }
  },
  data() {
    return{
      dialogVisible:true
    }
  },
  methods: {
    close() {
      this.$emit('on-close')
    }
  }
}
</script>

<style lang="scss" scoped>
.hwb-dialog{
  color: #fff;
  ::v-deep{
    .el-dialog{
      border: 0px !important;
      background: transparent;
      position: relative;
      .el-dialog__header{
        width: 100%;
        height: 34px;
        padding: 0;
        box-sizing: border-box;
        background: rgba(21,95,192,0.3);
        box-shadow: inset 0px 0px 10px 0px #00142F;
        border: 1px solid #73A6D1;
        backdrop-filter: blur(2px);
        .el-dialog__title{
          box-sizing: border-box;
          padding-left:47px;
          font-family: YouSheBiaoTiHei;
          display: block;
          width: 100%;
          height: 34px;
          line-height: 32px;
          font-size: 22px;
          font-weight: bold;
          text-align: left;
          backdrop-filter: blur(2px);
          background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 36%, #54ACFF 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .el-dialog__headerbtn{
          display: none;
        }
      }
      .guanbi{
        position: absolute;
        top: -30px;
        right: 16px;
        font-size: 22px;
        font-family: YouSheBiaoTiHei;
        color: #FFFFFF;
        cursor: pointer;
      }
      .jiantou{
        position: absolute;
        top: -27px;
        left: 8px;
        font-size: 22px;
        font-family: YouSheBiaoTiHei;
        color: #FFFFFF;
      }
      .el-dialog__body{
        background: rgba(40,53,122,0.1);
        border: 1px solid #73A6D1;
        border-top: 0;
        backdrop-filter: blur(2px);
      }
    }
    
  }
}

</style>