<template>
    <div>
        <Dialog v-if="approvalVisible" :create="true" @on-close="closeIDsPopup" title="公告管理" :left="140" :top="105"
            :width="1748">
            <!--待审批 -->
            <div class="Box">

                <!-- 赛选栏 -->
                <div class="select">

                    <el-date-picker format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" class="mr5 ml30"
                        v-model="edit_form.startTime" type="datetime" placeholder="请选择开始日期">
                    </el-date-picker>-
                    <el-date-picker format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" class="mr10"
                        v-model="edit_form.endTime" type="datetime" placeholder="请选择结束日期">
                    </el-date-picker>
                    <div class="btn" @click="search">搜索</div>
                    <div class="btn" @click="reset">重置</div>

                    <JsonExcel class="dib" :data="dataSource" :fields="json_fields" name="公告统计.xls">
                        <div class="btn">导出</div>
                    </JsonExcel>
                    <div class="btn" @click="dialogVisible = true">新增</div>

                </div>
                <!-- 表格 -->
                <Table class="task-table" :columns="columns" :dataSource="dataSource" :pagination="pagination"
                    @size-change="
                    (size) => {
                        pagination.size = size;
                        async_get_list();
                    }" @page-change="(page) => { async_get_list(page) }">

                    <template #index="{ row }">
                        <div class="action">
                            {{ row.index }}
                        </div>
                    </template>
                    <template #approvalStatus="{ row }">
                        <div class="action" v-if="row.approvalStatus">
                            {{ approvalStatus[row.approvalStatus].title }}
                        </div>
                    </template>
                    <template #borrowUserType="{ row }">
                        <div class="action" v-if="row.borrowUserType">
                            <!-- 借用类型 -->
                            {{ row.borrowUserType == 1 ? '个人' : '单位' }}
                        </div>
                    </template>
                    <template #aircraftType="{ row }">
                        <div class="action">
                            <!-- 无人机类型 -->
                            {{ aircraftType[row.aircraftType] }}
                        </div>
                    </template>
                    <template #action="{ row }">
                        <div class="">
                            <div class=" tc  ">


                                <el-tooltip content="删除" placement="top" effect="light">
                                    <div class=" vas dib cp iconfont f4f7 f18 icon-shanchu1" @click="delect(row.id)">
                                    </div>
                                </el-tooltip>
                                <div class="w20 dib mr5  h20 cp">
                                    <div v-if="row.isTop == 1" @click="isTop(2, row.id)">
                                        <el-tooltip content="取消置顶" placement="top" effect="light">
                                            <img class="dib w20 mt2 h25" src="./img/fasle.svg" alt="">
                                        </el-tooltip>

                                    </div>
                                    <div v-else @click="isTop(1, row.id)">
                                        <el-tooltip content="消息置顶" placement="top" effect="light">
                                            <img class="dib w20  mt2 h25" src="./img/true.svg" alt="">
                                        </el-tooltip>

                                    </div>
                                </div>
                                <el-tooltip content="详情" placement="top" effect="light">
                                    <div  class=" vas dib cp iconfont f4f7 f18 icon-chakanxiangqing" @click="deilList(row)">
                                    </div>
                                </el-tooltip>
                            </div>


                        </div>
                    </template>
                </Table>
                <el-dialog title="新增公告" append-to-body :visible.sync="dialogVisible" width="30%"
                    :before-close="handleClose">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="公告标题" prop="headline">
                            <el-input v-model="ruleForm.headline"></el-input>
                        </el-form-item>
                        <el-form-item label="内容" prop="content">
                            <el-input type="textarea" v-model="ruleForm.content"></el-input>
                        </el-form-item>
                        <el-form-item label="是否置顶">
                            <el-switch :active-value="1" :inactive-value="2" v-model="ruleForm.isTop"
                                active-color="#13ce66" inactive-color="#ff4949">
                            </el-switch>
                        </el-form-item>

                        <el-form-item>
                            <div class="btn h20 lh20" @click="resetForm">返回</div>
                            <div class="btn h20 lh20" @click="submitForm">立即创建</div>
                        </el-form-item>
                    </el-form>

                </el-dialog>
            </div>
        </Dialog>


        <Dialog v-else :create="true" @on-close="backList" :title="edit_form.type == 1 ? '公告详情' : '公告详情'" :left="140"
            :top="105" :width="880">
            <div class="djInfoBox cf wh100">
                <div class="wh100" v-if="detailslList != null">
                    <div class="mb20 f16"><span class="dec dib mr10"></span> 公告信息</div>
                    <div class="item">
                        <div>序号<span>{{detailslList.index}}</span></div>
                        <div>创建时间：<span>{{detailslList.createTime}}</span></div>
                        <div>创建单位：<span>{{detailslList.createOrganization}} </span></div>
                        <div>创建人姓名：<span>{{detailslList.createUser}}</span></div>
                        <div>创建人手机号：<span>{{detailslList.createUserPhone}}</span></div>
                        <div>标题：<span>{{detailslList.headline}}</span></div>
                    </div>
                    <div class="jcsb "><span class="dib w60" style="color:#8799b7"> 内容:</span>
                            <el-input type="textarea" readonly :rows="5" placeholder="" v-model="detailslList.content">
                            </el-input>
                        </div>
                </div>

            </div>
        </Dialog>

    </div>
</template>
<script>
import Dialog from "@/pages/civilAircraft/components/dialog"
import Table from "@/components/common/table-new-hwb"; // 列表组件
import API from "@/api";
import JsonExcel from 'vue-json-excel'

export default {
    components: { Dialog, Table, JsonExcel },

    data() {
        return {
            ruleForm: {
                headline: '',
                content: "",
                isTop: 1
            },
            rules: {
                headline: [
                    { required: true, message: '请输入公告标题', trigger: 'blur' },
                ],
                content: [
                    { required: true, message: '请输入公告内容', trigger: 'blur' }
                ]
            },
            dialogVisible: false,
            textarea: '',
            approvalVisible: true,
            approvalStatusNum: [
                {
                    title: '待审批',
                    num: 12,
                    default: 'dsp',
                    icon: require('@/assets/images/civilAircraft/dsp.svg'),
                    color: "#50F9F4FF"

                },
                {
                    title: '已审批',
                    num: 12,
                    default: "sptg",
                    icon: require('@/assets/images/civilAircraft/sptg.svg'),
                    color: "#00C4FF"

                }
            ],
            dataSource: [
            ],
            json_fields: {
                "序号": "index",    //常规字段
                "创建时间": "createTime",
                "创建单位": "createOrganization",
                "创建人姓名": "createUser",
                "创建人手机号": "createUserPhone",
                "标题": "headline",
                "内容": "content",
            },
            columns: [
                { prop: "index", label: "序号", slot: true, width: "100px" },
                { prop: "createTime", label: "创建时间", width: "230px" },
                { prop: "createOrganization", label: "创建单位", width: "170px" },
                { prop: "createUser", label: "创建人姓名", width: "170px" },
                { prop: "createUserPhone", label: "创建人手机号", width: "170px" },
                { prop: "headline", label: "标题", width: "170px" },
                { prop: "content", label: "内容", width: "300px" },
                { prop: "action", slot: true, label: "操作", width: "270px" },
            ],
            pagination: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
            aircraftType: {
                1: '无人机',
                2: '航空模型',
                3: '其他',
            },
            edit_form: {  // 发起需求表单字段
                "endTime": "",
                "page": 1,
                "size": 10,
                "startTime": ""
            },
            selectList: null, // 赛选的机构
            approvalStatus: {
                1: { title: "待同意", color: "color:'#e1ab3c'" },
                2: { title: "申请同意", color: "color:#52c759" },
                3: { title: "不同意", color: "color:#e33438" }
            },
            drd: {
                cates: [
                    { id: 1, title: "无人机" },
                    { id: 2, title: "航空模型" },
                    { id: 3, title: "其它" },

                ],

            },
            approvalList: null,
            detailslList: null
        }
    },
    created() {
        this.async_get_list()
    },
    methods: {
        backList(){
             this.approvalVisible = true
        },
        deilList(row){
            console.log(row);
            this.approvalVisible = false
            this.detailslList = row
        },
        search() {
            this.pagination.page = 1
            this.async_get_list()
        },
        async isTop(isTop, id) {
            let res = await API.AIRCRAFT.upInfo({
                id,
                isTop
            })
            if (res.code == 200) {
                this.$message.success('操作成功')
                this.async_get_list()
            }
        },
        submitForm() {
            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    let res = await API.AIRCRAFT.noticeInfoSave(this.ruleForm)
                    console.log(res);
                    if (res.code == 200) {
                        this.$message.success('添加成功')
                        this.dialogVisible = false
                        this.ruleForm = {
                            headline: '',
                            content: "",
                            isTop: 1
                        }
                        this.async_get_list(1)
                    }
                } else {
                    return false;
                }
            });
        },
        resetForm() {
            this.$refs['ruleForm'].resetFields();
            this.dialogVisible = false

        },
        handleClose() {
            this.$refs['ruleForm'].resetFields();
            this.dialogVisible = false
        },
        delect(id) {
            this.$confirm('此操作将永久删除此消息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await API.AIRCRAFT.noticeInfoDel({ id })
                if (res.code == 200) {
                    this.$message.success('操作成功！')
                    this.reset()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        back() {
            this.approvalList = null
            this.textarea = null;
            this.approvalVisible = true
            this.$forceUpdate()
        },
        async approvalHandClick(num) {
            if (this.textarea == '') return this.$message.warning('请输入备注信息！')
            let res = await API.AIRCRAFT.approval({
                id: this.approvalList?.id,
                messageStatus: num,
                auditRemark: this.textarea
            })
            if (res.code == 200) {
                this.back()
                this.$message.success('操作成功')
                this.pagination.page = 1
                this.async_get_list()
            } if (res.code == 201) {
                return this.$message.warning('已审批，无需审批')
            }
        },
        // 审批
        approval(approval) {
            if (approval) {
                let data = this.dataSource.filter(item => item.id == approval.id)
                this.approvalList = JSON.parse(JSON.stringify(data[0]))
            }
            this.approvalVisible = false
        },
        reset() {
            this.edit_form = {  // 发起需求表单字段
                "endTime": "",
                "page": 1,
                "size": 10,
                "startTime": ""
            }
            this.async_get_list()

        },

        closeIDsPopup() {
            this.$emit('closeDemand');
        },
        async async_get_list(page) {
            if (page) {
                this.pagination.page = page
            }
            for (const key in this.pagination) {
                if (this.edit_form[key]) {
                    this.edit_form[key] = this.pagination[key]
                }
            }
            let res = await API.AIRCRAFT.noticeInfoGetList(this.edit_form)
            if (res.code == 200 && res.data.length > 0) {
                this.dataSource = res.data.map((item, index) => {
                    return ({
                        ...item,
                        index: index + 1
                    })
                })
                this.pagination.total = res.total
                this.$forceUpdate()
            } else {
                this.dataSource = []
            }

        }
    },
}
</script>
<style lang="scss" scoped>
.f4f7 {
    color: #77f4f7
}

.Box {
    min-height: 50vh;

    .tableSelect {
        display: flex;
        justify-content: flex-start;

        .item {
            cursor: pointer;
            display: flex;
            text-align: center;
            justify-content: space-evenly;
            align-items: center;
        }

        .dsp {
            background-image: url(~@/assets/images/civilAircraft/1.png);

            &.selected {
                background-image: url(~@/assets/img/click-3.png);
            }
        }

        .sptg {
            background-image: url(~@/assets/images/civilAircraft/2.png);

            &.selected {
                background-image: url(~@/assets/img/click-1.png);
            }
        }
    }

    .select {
        margin: 0px 0 24px 0;

        ::v-deep {
            .el-input__inner {
                background-color: #000;
                border: 0;
                height: 30px;
                line-height: 30px;
                color: #fff;
                font-size: 14px;
            }
        }

        .button {
            margin-right: 10px;
            width: 210px;
        }

        .search-button {
            display: inline-block;
            width: 54px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;
            border-radius: 4px;
            background-color: #000;
            color: #fff;
            text-align: center;
            box-sizing: border-box;
        }


    }

    .task-table {
        min-height: 50vh;
    }
}

.btn {
    padding: 5px 10px;
    display: inline-block;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 2px;
    border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    margin-right: 10px;
    cursor: pointer;
}

.btn:hover {
    background: rgba(38, 71, 238, 0.91);

}

.djInfoBox {
    display: flex;

    .dec {
        width: 4px;
        height: 17px;
        background: #FFC300;
        border-radius: 2px;
    }

    .item {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;

        div {
            min-width: 30%;
            margin-bottom: 20px;
            flex: 1;
            color: #8799b7;
            white-space: nowrap;

            span {
                display: inline-block;
                overflow: hidden;
                vertical-align: bottom;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 200px;
                margin-left: 20px;
                color: #effaff;
            }
        }
    }

}

::v-deep {
    .el-divider--vertical {
        height: 100%;
    }


    .el-textarea__inner {
        display: inline-block;
        box-sizing: border-box;
        background: rgba(12, 34, 67, 0.5) !important;
        background: transparent;
        border: 1px solid #009aff;
        border-radius: 0;
        font-size: 16px;
        color: #9fc4ff;
        letter-spacing: 0;
        font-weight: 400;
    }

    .el-dialog {
        margin-top: 5vh;
    }

}

::v-deep .el-input__icon {
    line-height: 30px;
}

::v-deep {
    .el-input__inner {
        background: rgba(12, 34, 67, 0.5);
        border: 1px solid #009aff;
        color: #9fc4ff;
    }

    .el-form-item__label {
        color: #fff;
    }
}
</style>