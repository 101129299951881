<template>
    <div>
        <Dialog v-if="approvalVisible" class="60vh" :create="true" @on-close="closeIDsPopup" title="意见反馈" :left="140" :top="105"
            :width="1748">
            <!--待审批 -->
            <div class="Box">

                <!-- 赛选栏 -->
                <div class="select">
                    <!-- <div class="btn" @click="reset">新增</div> -->
                    <el-select v-model="edit_form.feedbackType" size="mini" clearable placeholder="请选择反馈类型"
                        class="button">
                        <el-option v-for="item in drd.yjfkType" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>

                    <el-date-picker class="mr10 ml30" v-model="edit_form.startTime" type="datetime"
                        placeholder="请选择开始时间">
                    </el-date-picker>-
                    <el-date-picker class="mr10" v-model="edit_form.endTime" type="datetime" placeholder="请选择结束时间">
                    </el-date-picker>
                    <el-input v-model="edit_form.name" class="w170 mr10 dib " size="mini" placeholder="请输入标题或内容" clearable>
                    </el-input>
                    <div class="btn" @click="searchData">搜索</div>
                    <div class="btn" @click="reset">重置</div>
                    <JsonExcel class="dib" :data="dataSource" :fields="json_fields" name="反馈意见统计.xls">
                        <div class="btn">导出</div>
                    </JsonExcel>
                </div>
                <!-- 表格 -->
                <Table class="task-table" :columns="columns" :dataSource="dataSource" :pagination="pagination"
                    @size-change="
                    (size) => {
                        pagination.size = size;
                        async_get_list();
                    }" @page-change="(page) => { async_get_list(page) }">

                    <template #index="{ row }">
                        <div class="action">
                            {{ row.index }}
                        </div>
                    </template>

                    <template #feedbackType="{ row }">
                        <div class="action" v-if="row.feedbackType">
                            <!-- 借用类型 -->
                            {{ row.feedbackType == 1 ? '意见建议' : '使用问题' }}
                        </div>
                    </template>

                    <template #action="{ row }">
                        <div class="">
                            <div class="">
                                <el-tooltip content="删除" placement="top" effect="light">
                                    <div class="vas mr5 dib cp iconfont f4f7 f20 icon-shanchu1" @click="delect(row.id)"></div>
                                </el-tooltip>
                                <!-- <el-tooltip content="详情" placement="top" effect="light">
                                    <div class="dib ml30 cp iconfont f4f7 f20 icon-chakanxiangqing"
                                        @click="approval(row)"></div>
                                </el-tooltip> -->
                                 <el-tooltip content="详情" placement="top" effect="light">
                                    <div  class=" vas dib cp iconfont f4f7 f18 icon-chakanxiangqing" @click="deilList(row)">
                                    </div>
                                </el-tooltip>
                            </div>



                        </div>
                    </template>
                </Table>
            </div>
        </Dialog>
        <!-- { prop: "index", label: "序号", slot: true, width: "100px" },
                { prop: "feedbackTime", label: "反馈时间", width: "230px" },
                { prop: "feedbackType", slot: true, label: "反馈类型", width: "170px" },
                { prop: "feedbackTitle", label: "标题", width: "170px" },
                { prop: "feedbackInfo", label: "内容", width: "300px" },
                { prop: "feedbackUserName", label: "反馈人姓名", width: "170px" },
                { prop: "feedbackUserPhone", label: "创建人手机号", width: "170px" },
                { prop: "action", slot: true, label: "操作", width: "270px" }, -->
        <Dialog v-else :create="true" @on-close="backList" :title="edit_form.type == 1 ? '意见详情' : '意见详情'" :left="140"
            :top="105" :width="880">
            <div class="djInfoBox cf wh100">
                <div class="wh100" v-if="detailslList != null">
                    <div class="mb20 f16"><span class="dec dib mr10"></span> 意见信息</div>
                    <div class="item">
                        <div>序号<span>{{detailslList.index}}</span></div>
                        <div>反馈时间：<span>{{detailslList.feedbackTime}}</span></div>
                        <div>反馈类型：<span>{{detailslList.feedbackType == 1 ? '意见建议' : '使用问题'}} </span></div>
                        <div>反馈人姓名：<span>{{detailslList.feedbackUserName}}</span></div>
                        <div>创建人手机号：<span>{{detailslList.feedbackUserPhone}}</span></div>
                        <div>标题：<span>{{detailslList.feedbackTitle}}</span></div>
                    </div>
                    <div class="jcsb "><span class="dib w60" style="color:#8799b7"> 内容:</span>
                            <el-input type="textarea" readonly :rows="5" placeholder="" v-model="detailslList.feedbackInfo">
                            </el-input>
                        </div>
                </div>

            </div>
        </Dialog>

    </div>
</template>
<script>
import Dialog from "@/pages/civilAircraft/components/dialog"
import Table from "@/components/common/table-new-hwb"; // 列表组件
import API from "@/api";
import JsonExcel from 'vue-json-excel'

export default {
    components: { Dialog, Table, JsonExcel },

    data() {
        return {
            textarea: '',
            approvalVisible: true,
            approvalStatusNum: [
                {
                    title: '待审批',
                    num: 12,
                    default: 'dsp',
                    icon: require('@/assets/images/civilAircraft/dsp.svg'),
                    color: "#50F9F4FF"

                },
                {
                    title: '已审批',
                    num: 12,
                    default: "sptg",
                    icon: require('@/assets/images/civilAircraft/sptg.svg'),
                    color: "#00C4FF"

                }
            ],
            dataSource: [
            ],
            json_fields: {
                "序号": "index",    //常规字段
                "反馈时间": "feedbackTime",
                "反馈类型(1:意见建议 2:使用问题)": "feedbackType",
                "标题": "feedbackTitle",
                "内容": "feedbackInfo",
                "反馈人姓名": "feedbackUserName",
                "创建人手机号": "feedbackUserPhone",
            },
            columns: [
                { prop: "index", label: "序号", slot: true, width: "100px" },
                { prop: "feedbackTime", label: "反馈时间", width: "230px" },
                { prop: "feedbackType", slot: true, label: "反馈类型", width: "170px" },
                { prop: "feedbackTitle", label: "标题", width: "170px" },
                { prop: "feedbackInfo", label: "内容", width: "300px" },
                { prop: "feedbackUserName", label: "反馈人姓名", width: "170px" },
                { prop: "feedbackUserPhone", label: "创建人手机号", width: "170px" },
                { prop: "action", slot: true, label: "操作", width: "270px" },
            ],
            pagination: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
            aircraftType: {
                1: '无人机',
                2: '航空模型',
                3: '其他',
            },
            edit_form: {  // 发起需求表单字段
                "endTime": "",
                "page": 1,
                "size": 10,
                "feedbackType": null,
                "name": null,
                "startTime": ""
            },
            selectList: null, // 赛选的机构
            approvalStatus: {
                1: { title: "待同意", color: "color:'#e1ab3c'" },
                2: { title: "申请同意", color: "color:#52c759" },
                3: { title: "不同意", color: "color:#e33438" }
            },
            drd: {
                cates: [
                    { id: 1, title: "无人机" },
                    { id: 2, title: "航空模型" },
                    { id: 3, title: "其它" },

                ],
                yjfkType: [
                    {
                        id: 1,
                        title: '意见建议'
                    },
                    {
                        id: 2,
                        title: '使用问题'
                    }
                ]

            },
            approvalList: null
        }
    },
    created() {
        this.async_get_list()
    },
    methods: {
        backList(){
             this.approvalVisible = true
        },
        deilList(row){
            console.log(row);
            this.approvalVisible = false
            this.detailslList = row
        },
        delect(id) {
            this.$confirm('此操作将永久删除此消息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await API.AIRCRAFT.feedbackDel({ id })
                if (res.code == 200) {
                    this.$message.success('操作成功！')
                    this.async_get_list()
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        back() {
            this.approvalList = null
            this.textarea = null;
            this.approvalVisible = true
            this.$forceUpdate()
        },
        async approvalHandClick(num) {
            if (this.textarea == '') return this.$message.warning('请输入备注信息！')
            let res = await API.AIRCRAFT.approval({
                id: this.approvalList?.id,
                messageStatus: num,
                auditRemark: this.textarea
            })
            if (res.code == 200) {
                this.back()
                this.$message.success('操作成功')
                this.pagination.page = 1
                this.async_get_list()
            } if (res.code == 201) {
                return this.$message.warning('已审批，无需审批')
            }
        },
        // 审批
        approval(approval) {
            if (approval) {
                let data = this.dataSource.filter(item => item.id == approval.id)
                this.approvalList = JSON.parse(JSON.stringify(data[0]))
            }
            this.approvalVisible = false
        },
        reset() {
            this.edit_form = {  // 发起需求表单字段
                "endTime": "",
                "page": 1,
                "size": 10,
                "feedbackType": null,
                "name": null,
                "startTime": ""
            }
            this.async_get_list()

        },
        searchData() { // 搜索按钮
            this.pagination.page = 1
            this.async_get_list()
        },
        closeIDsPopup() {
            this.$emit('closeDemand');
        },
        async async_get_list(page) {
            if (page) {
                this.pagination.page = page
            }
            for (const key in this.pagination) {
                if (this.edit_form[key]) {
                    this.edit_form[key] = this.pagination[key]
                }
            }
            let res = await API.AIRCRAFT.feedbackLists(this.edit_form)
            if (res.code == 200) {
                this.dataSource = res.data.map((item, index) => {
                    return ({
                        ...item,
                        index: index + 1
                    })
                })
                this.pagination.total = res.total
            }else{
                this.dataSource =[]
            }

        }
    },
}
</script>
<style lang="scss" scoped>
.f4f7 {
    color: #77f4f7
}

.Box {
    .tableSelect {
        display: flex;
        justify-content: flex-start;

        .item {
            cursor: pointer;
            display: flex;
            text-align: center;
            justify-content: space-evenly;
            align-items: center;
        }

        .dsp {
            background-image: url(~@/assets/images/civilAircraft/1.png);

            &.selected {
                background-image: url(~@/assets/img/click-3.png);
            }
        }

        .sptg {
            background-image: url(~@/assets/images/civilAircraft/2.png);

            &.selected {
                background-image: url(~@/assets/img/click-1.png);
            }
        }
    }

    .select {
        margin: 0px 0 24px 0;

        ::v-deep {
            .el-input__inner {
                background-color: #000;
                border: 0;
                height: 30px;
                line-height: 30px;
                color: #fff;
                font-size: 14px;
            }
        }

        .button {
            margin-right: 10px;
            width: 210px;
        }

        .search-button {
            display: inline-block;
            width: 54px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;
            border-radius: 4px;
            background-color: #000;
            color: #fff;
            text-align: center;
            box-sizing: border-box;
        }


    }

    .task-table {
        min-height: 50vh;
        max-height: 60vh;
    }
}

.btn {
    padding: 5px 10px;
    display: inline-block;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 2px;
    border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    margin-right: 10px;
    cursor: pointer;
}

.btn:hover {
    background: rgba(38, 71, 238, 0.91);

}

.djInfoBox {
    display: flex;

    .dec {
        width: 4px;
        height: 17px;
        background: #FFC300;
        border-radius: 2px;
    }

    .item {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;

        div {
            min-width: 30%;
            margin-bottom: 20px;
            flex: 1;
            color: #8799b7;
            white-space: nowrap;

            span {
                display: inline-block;
                overflow: hidden;
                vertical-align: bottom;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 200px;
                margin-left: 20px;
                color: #effaff;
            }
        }
    }

}

::v-deep {
    .el-divider--vertical {
        height: 100%;
    }


    .el-textarea__inner {
        display: inline-block;
        box-sizing: border-box;
        background: rgba(12, 34, 67, 0.5) !important;
        background: transparent;
        border: 1px solid #009aff;
        border-radius: 0;
        font-size: 16px;
        color: #9fc4ff;
        letter-spacing: 0;
        font-weight: 400;
    }

    .el-dialog {
        margin-top: 5vh;
    }

}

::v-deep .el-input__icon {
    line-height: 30px;
}
</style>